require('@rails/ujs').start()
require('turbolinks').start()

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'

import './games'
import './players'

import './stylesheets/application'
